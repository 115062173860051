import Button from './ui/Button'
import FormPageIndicator from './FormPageIndicator'
import GuarantorFields from './GuarantorFields'
import Input from './ui/Input'
import DateEntry from './ui/DateEntry'
import React from 'react'
import sentenceCase from 'sentence-case'
import { FieldArray, Field } from 'formik'
import { withRouter } from 'react-router-dom'
import { withContentContainer } from '../ContentContainerContext'

import {
  TENANT_BLANK,
  GUARANTOR_FIELDS,
  NOT_REQUIRED_FIELDS,
} from '../utils/constants'

class TenantArray extends React.Component {
  componentDidMount() {
    const {
      numberOfTenants,
      setFieldValue,
      values: { tenants },
    } = this.props

    if (numberOfTenants > tenants.length) {
      for (let i = tenants.length; i < numberOfTenants; i++) {
        Object.keys(TENANT_BLANK).forEach(value => {
          setFieldValue(`tenants[${i}]${value}`, '', true)
        })
      }
    }
  }

  render() {
    const {
      decrementNumberOfTenants,
      editing,
      errors,
      focusFirstErrorInContent,
      handleGuarantor,
      history,
      incrementNumberOfTenants,
      setFieldValue,
      setTouched,
      setFieldError,
      showGuarantor,
      touched,
    } = this.props
    const { tenants } = this.props.values

    const enforceUniqueEmails = () => {
      let tenantEmails = []
      let index = null
      for (var i = 0; i < tenants.length; i++) {
        tenantEmails.push(tenants[i].email)
        if (new Set(tenantEmails).size !== tenantEmails.length) {
          index = i
          break
        }
      }
      return index
    }

    const tenantFields = [
      {
        name: 'firstName',
        component: Input,
      },
      {
        name: 'lastName',
        component: Input,
      },
      {
        name: 'email',
        component: Input,
        type: 'email',
        hint: `Please ensure this email is correct.
        Tenants must have unique email address so we can email them to sign up`,
      },
      {
        name: 'phoneNumber',
        component: Input,
      },
      {
        name: 'dateOfBirth',
        component: DateEntry,
      },
    ]

    const newTenant = {}
    const fieldsToTouch = {}
    tenantFields.forEach(field => {
      newTenant[field.name] = ''
      fieldsToTouch[field.name] = true
    })

    if (tenants) {
      tenants.forEach(tenant => {
        if (tenant.guarantor) {
          fieldsToTouch.guarantor = {}
          GUARANTOR_FIELDS.forEach(field => {
            if (!NOT_REQUIRED_FIELDS.includes(field)) {
              fieldsToTouch.guarantor[field] = true
            }
          })
        }
      })
    }

    const tenantCount = Math.max(
      this.props.numberOfTenants,
      tenants ? tenants.length : 0
    )
    const tenantCountArray = new Array(tenantCount).fill(0)

    const deleteGuarantor = (index, bool) => {
      if (tenants[index]) {
        setFieldValue(`tenants[${index}].guarantor`, undefined, true)
      }
      if (
        errors &&
        errors.tenants &&
        errors.tenants[index] &&
        errors.tenants[index].guarantor
      ) {
        setFieldValue(`errors.tenants[${index}].guarantor`, undefined, true)
      }
      handleGuarantor(index, bool)
    }

    const isTouched = () => {
      if (touched.tenants) {
        let allTouched = touched.tenants.map(tenant => {
          if (!tenant) tenant = {}
          return Object.keys(tenant).length === 5
        })
        if (allTouched.includes(false)) return false
      } else {
        return false
      }
    }

    const toTouch = {
      tenants: new Array(this.props.numberOfTenants).fill(fieldsToTouch),
    }

    return (
      <>
        <div className="wrap-content u-push-large">
          <h1>Tenant details</h1>
          <p>
            Please make sure this information is correct, as we'll use it to
            contact your tenants.
          </p>
          <FieldArray
            name="tenants"
            render={arrayHelpers => {
              return (
                <div>
                  <>
                    {tenantCountArray.map((tenant, index) => (
                      <div key={index} className="u-push-large u-clearfix">
                        <h4>
                          {`Tenant ${index + 1}`}
                          {index !== 0 && (
                            <div className="u-float-right">
                              <Button
                                className="u-col-main"
                                size="small"
                                onClick={() => {
                                  decrementNumberOfTenants()
                                  arrayHelpers.remove(index)
                                  if (tenants[index].guarantor) {
                                    deleteGuarantor(index, false)
                                  }
                                }}
                              >
                                Remove
                              </Button>
                            </div>
                          )}
                        </h4>
                        {tenantFields.map((tenantField, i) => {
                          return (
                            <Field
                              key={`tenant${i}`}
                              name={`tenants[${index}]${tenantField.name}`}
                              render={({ field }) => (
                                <tenantField.component
                                  className={i === 0 ? 'u-push-3' : null}
                                  fullWidth={tenantField.component === Input}
                                  hint={tenantField.hint}
                                  label={sentenceCase(tenantField.name)}
                                  type={tenantField.type}
                                  placeholder={sentenceCase(tenantField.name)}
                                  {...field}
                                  error={
                                    touched &&
                                    touched.tenants &&
                                    touched.tenants[index] &&
                                    touched.tenants[index][tenantField.name] &&
                                    errors &&
                                    errors.tenants &&
                                    errors.tenants[index]
                                      ? errors.tenants[index][tenantField.name]
                                      : null
                                  }
                                />
                              )}
                            />
                          )
                        })}

                        <div>
                          {!showGuarantor[index] ? (
                            <Button
                              className="u-col-main"
                              size="small"
                              onClick={() => handleGuarantor(index, true)}
                            >
                              Add guarantor
                            </Button>
                          ) : (
                            <div className="u-push-3 u-clearfix">
                              <h3
                                className="u-push-3 u-float-left"
                                style={{ marginTop: 0 }}
                              >
                                <span>Guarantor</span>
                                <div className="text-small">
                                  For Tenant {index + 1}
                                </div>
                              </h3>
                              <Button
                                className="u-float-right u-col-main"
                                size="small"
                                variant="secondary"
                                onClick={() => deleteGuarantor(index, false)}
                              >
                                Remove guarantor
                              </Button>
                            </div>
                          )}
                        </div>

                        {showGuarantor[index] ? (
                          <GuarantorFields
                            index={index}
                            errors={errors}
                            touched={touched}
                          />
                        ) : null}
                      </div>
                    ))}
                    <div className="flex-column u-push-large">
                      <Button
                        className="u-col-main"
                        size="small"
                        variant="secondary"
                        onClick={() => {
                          incrementNumberOfTenants()
                          arrayHelpers.push(newTenant)
                        }}
                      >
                        Add another tenant
                      </Button>
                    </div>
                  </>
                </div>
              )
            }}
          />
          <div className="flex-column">
            {editing ? (
              <Button
                className="u-push-large"
                fullWidth
                disabled={errors.tenants}
                onClick={() => {
                  var emailNotUnique = enforceUniqueEmails()
                  if (emailNotUnique) {
                    setFieldError(
                      `tenants[${emailNotUnique}].email`,
                      'Each tenant must have a unique email address'
                    )
                    focusFirstErrorInContent()
                  } else {
                    history.push('/form/review')
                  }
                }}
                variant="main"
              >
                Update
              </Button>
            ) : (
              <>
                <Button
                  className="u-push-large"
                  fullWidth
                  onClick={() => {
                    var emailNotUnique = enforceUniqueEmails()
                    if (isTouched() || errors.tenants) {
                      setTouched(toTouch)
                      focusFirstErrorInContent()
                    } else if (emailNotUnique) {
                      setFieldError(
                        `tenants[${emailNotUnique}].email`,
                        'Each tenant must have a unique email address'
                      )
                      focusFirstErrorInContent()
                    } else {
                      history.push('/form/property')
                    }
                  }}
                  variant="main"
                >
                  Next
                </Button>
                <FormPageIndicator />
              </>
            )}
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(withContentContainer(TenantArray))
