import React from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  withRouter,
} from 'react-router-dom'
import './App.css'
import Agreement from './components/Agreement'
import ImagePanel from './components/ImagePanel'
import Landing from './components/Landing'
import MarketProperty from './components/MarketProperty'
import OfferSent from './components/OfferSent'
import PreScreen from './components/PreScreen'
import TellAgent from './components/TellAgent'
import WizardForm from './components/WizardForm'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { ContentContainerContext } from './ContentContainerContext'

class ContentRoutes extends React.Component {
  constructor(props) {
    super(props)

    this.contentRef = React.createRef()
    this.state = {
      focusFirstErrorInContent: this.focusFirstErrorInContent,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      setTimeout(() => this.setScrollTop(0), 75)
    }
  }

  setScrollTop = (y = 0) => {
    this.contentRef.current.scrollTop = y
  }

  focusFirstErrorInContent = () => {
    // defer to give react a chance to render
    setTimeout(() => {
      const items = this.contentRef.current.querySelectorAll(
        '.Input.is-error, .Select.is-error, .TextArea.is-error'
      )
      const item = items[0]

      if (item) {
        const input = item.querySelectorAll(
          '.Input-input, .Select-select, .TextArea-textarea'
        )

        input[0].focus()
        this.setScrollTop(item.offsetTop)
      }
    })
  }

  render() {
    const { location } = this.props
    const { focusFirstErrorInContent } = this.state
    const manageDeposit = {
      title: 'Do you manage your deposit?',
      radioLabel: ['Yes', 'No'],
      text: [
        `If you usually manage the deposit yourself,
        it's simple to offer the choice of Zero Deposit to your tenants.`,
        `If you use an agent to manage the deposit,
        it’s easy to have them offer Zero Deposit to your tenants.`,
      ],
      linkTo: ['/agreement', '/tell-agent'],
    }

    const findTenants = {
      title: 'Have you found tenants?',
      radioLabel: ['Yes', 'Not yet'],
      text: [
        `If you have referenced tenants who are ready to move in,
          offer them the choice of Zero Deposit.
        `,
        `You can attract more tenants by
          marketing your property with Zero Deposit.`,
      ],
      linkTo: ['/landing', '/market-property'],
    }

    return (
      <ContentContainerContext.Provider value={{ focusFirstErrorInContent }}>
        <div className="content" ref={this.contentRef}>
          <div className="route-container">
            <TransitionGroup>
              <CSSTransition
                key={
                  location.pathname.split('/')[1] === 'offersent'
                    ? 'form'
                    : location.pathname.split('/')[1]
                }
                classNames="fade"
                timeout={150}
              >
                <div>
                  <Switch location={location}>
                    <Route
                      exact
                      path="/"
                      render={props => (
                        <PreScreen {...props} content={manageDeposit} />
                      )}
                    />
                    <Route path="/agreement" component={Agreement} />
                    <Route
                      exact
                      path="/find-tenants"
                      render={props => (
                        <PreScreen {...props} content={findTenants} />
                      )}
                    />
                    <Route path="/landing" component={Landing} />
                    <Route
                      path="/form"
                      render={props => <WizardForm {...props} />}
                    />
                    <Route path="/offersent" component={OfferSent} />
                    <Route path="/tell-agent" component={TellAgent} />
                    <Route path="/market-property" component={MarketProperty} />
                    <Redirect to="/" />
                  </Switch>
                </div>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </div>
      </ContentContainerContext.Provider>
    )
  }
}

const ContentRoutesWithRouter = withRouter(ContentRoutes)

const App = () => (
  <Router>
    <>
      <ContentRoutesWithRouter />
      {window.navigator.userAgent.indexOf('Trident') === -1 && <ImagePanel />}
    </>
  </Router>
)

export default App
