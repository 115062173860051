import AddressLookup from './AddressLookup'
import Button from './ui/Button'
import FormPageIndicator from './FormPageIndicator'
import Input from './ui/Input'
import DateEntry from './ui/DateEntry'
import React from 'react'
import pullAll from 'lodash/pullAll'
import sentenceCase from 'sentence-case'
import { ADDRESS_FIELDS, NOT_REQUIRED_FIELDS } from './../utils/constants'
import { Field } from 'formik'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'

const Property = props => {
  const {
    editing,
    errors,
    focusFirstErrorInContent,
    history,
    setTouched,
    touched,
  } = props

  let propertyFields = [
    'addressLine_1',
    'addressLine_2',
    'addressLine_3',
    'addressLine_4',
    'city',
    'postcode',
    'amount',
  ]

  const fieldsToTouch = { tenancyStart: true }
  let isError
  let isTouched

  propertyFields.forEach(field => {
    if (!NOT_REQUIRED_FIELDS.includes(field)) {
      fieldsToTouch[field] = true
    }
    if (errors && errors[field]) {
      isError = true
    }
    if (touched && touched[field]) {
      isTouched = true
    }
  })

  const fieldsWithoutAddress = pullAll([...propertyFields], ADDRESS_FIELDS)

  return (
    <>
      <div className="wrap-content u-push-large">
        <h1>Rental Details</h1>
        <p>
          Tell us the address of your rental property, when the tenancy starts
          and the rental amount{' '}
        </p>

        <AddressLookup
          className="u-push-3"
          prefix=""
          searchPlaceholder="Address (first line or postcode)"
        />

        <Field
          name="tenancyStart"
          render={({ field }) => (
            <DateEntry
              {...field}
              placeholder="Tenancy start"
              label="Tenancy start"
              error={
                touched && touched.tenancyStart && errors && errors.tenancyStart
                  ? errors.tenancyStart
                  : null
              }
            />
          )}
        />

        {fieldsWithoutAddress.map((propertyField, index) => {
          return (
            <Field
              key={`property${index}`}
              name={propertyField}
              render={({ field }) => (
                <Input
                  {...field}
                  className={classNames({
                    'Input--unit-pound': propertyField === 'amount',
                    'u-push-3': index === 0,
                  })}
                  placeholder={
                    propertyField !== 'amount'
                      ? sentenceCase(propertyField)
                      : 'Monthly Rent'
                  }
                  label={
                    propertyField !== 'amount'
                      ? sentenceCase(propertyField)
                      : 'Monthly Rent'
                  }
                  error={
                    touched &&
                    touched[propertyField] &&
                    errors &&
                    errors[propertyField]
                      ? errors[propertyField]
                      : null
                  }
                />
              )}
            />
          )
        })}

        {editing ? (
          <div className="flex-column">
            <Button
              className="u-push-large"
              fullWidth
              onClick={() => history.push('/form/review')}
              variant="main"
              disabled={
                !!(
                  errors.tenancyStart ||
                  errors.amount ||
                  errors.addressLine_1 ||
                  errors.city ||
                  errors.postcode
                )
              }
            >
              Update
            </Button>
          </div>
        ) : (
          <>
            <div className="flex-column">
              <Button
                className="u-push-large"
                fullWidth
                disabled={
                  !!(
                    errors.tenancyStart ||
                    errors.amount ||
                    errors.addressLine_1 ||
                    errors.city ||
                    errors.postcode
                  )
                }
                onClick={() => {
                  if (!isTouched || isError) {
                    setTouched(fieldsToTouch)
                    focusFirstErrorInContent()
                  } else {
                    history.push('/form/review')
                  }
                }}
                variant="main"
              >
                NEXT
              </Button>
              <FormPageIndicator />
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default withRouter(Property)
