import Header from './Header'
import Landlord from './Landlord'
import Property from './Property'
import React from 'react'
import References from './References'
import Review from './Review'
import TenantArray from './TenantArray'
import validationSchema from '../utils/validationSchema'
import { Formik } from 'formik'
import { Persist } from 'formik-persist'
import { Switch, Route, Redirect } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { withRouter } from 'react-router-dom'
import {
  TENANT_BLANK,
  TENANT_COUNT_MAX,
  TENANT_COUNT_MIN,
} from '../utils/constants'

class WizardForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      referenceProviders: [],
      editing: {
        landlord: false,
        tenants: false,
        property: false,
      },
      termsAndConditions: false,
      passedReferencesCheck: false,
      showGuarantor: {},
      numberOfTenants: 1,
      values: {
        landlord: {
          name: '',
          email: '',
          phoneNumber: '',
          addressLine_1: '',
          addressLine_2: '',
          addressLine_3: '',
          addressLine_4: '',
          city: '',
          postcode: '',
        },
        tenants: [{ ...TENANT_BLANK }],
        passedReferences: '',
        referenceCompany: '',
        addressLine_1: '',
        addressLine_2: '',
        addressLine_3: '',
        addressLine_4: '',
        city: '',
        postcode: '',
        amount: '',
        tenancyStart: '',
      },
    }
  }

  addToLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value))
  }

  handleSubmit = (e, props) => {
    e.preventDefault()
    //adjust payload to reflect API contract
    const { passedReferences, ...restValues } = props.values
    const { setErrors } = props
    const { history } = this.props

    restValues.passedReferences =
      passedReferences === 'with guarantor' ? true : passedReferences === true
    //send the amount as monthlyRent
    restValues.monthlyRent = restValues.amount

    var url = `${process.env.REACT_APP_API_URL}introduction/`
    fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(restValues),
    })
      .then(response => {
        if (response.ok) {
          window.localStorage.clear()
          history.push('/offersent')
        } else {
          response.json().then(data => {
            setErrors({ submissionError: data.error })
          })
        }
      })
      .catch(err => {
        setErrors({
          requestError: err,
        })
      })
  }

  handleEditing = e => {
    e.persist()
    this.setState(
      {
        editing: { [e.currentTarget.name]: true },
      },
      () => this.addToLocalStorage('editing', this.state.editing)
    )
    this.props.history.push(`/form/${e.currentTarget.name}`)
  }

  handleGuarantor = (index, bool) => {
    this.setState({
      showGuarantor: { ...this.state.showGuarantor, [index]: bool },
    })
  }

  manageTenantList = numberOfTenants => {
    const {
      values,
      values: { tenants },
    } = this.state

    let nextTenants = [...tenants]
    if (numberOfTenants > tenants.length) {
      nextTenants.push(
        ...new Array(numberOfTenants - tenants.length).fill({ ...TENANT_BLANK })
      )
    } else if (numberOfTenants < tenants.length) {
      nextTenants = nextTenants.slice(0, numberOfTenants)
    }
    this.setState({
      numberOfTenants,
      values: { ...values, tenants: nextTenants },
    })
  }

  setNumberOfTenants = e => {
    this.manageTenantList(e.target.value)
  }

  onStepNumberOfTenants = numberOfTenants => {
    this.manageTenantList(numberOfTenants)
  }

  incrementNumberOfTenants = () => {
    // only add tenants if we're allowed to
    const { numberOfTenants } = this.state
    const incrementBy = numberOfTenants + 1 <= TENANT_COUNT_MAX ? 1 : 0
    this.setState({ numberOfTenants: numberOfTenants + incrementBy })
    return incrementBy !== 0
  }

  decrementNumberOfTenants = () => {
    // only remove tenants if we're allowed to
    const { numberOfTenants } = this.state
    const decrementBy = numberOfTenants - 1 >= TENANT_COUNT_MIN ? 1 : 0
    this.setState({ numberOfTenants: numberOfTenants - decrementBy })
    return decrementBy !== 0
  }

  handleTermsAndConditions = () => {
    this.setState({ termsAndConditions: !this.state.termsAndConditions })
  }

  handlePassedReferencesCheck = () => {
    this.setState({ passedReferencesCheck: !this.state.passedReferencesCheck })
  }

  fetchReferenceProviders = async () => {
    let url = process.env.REACT_APP_REFERENCE_PROVIDERS_URL

    return await fetch(url)
      .then(blob => blob.json())
      .then(data => {
        this.setState({ referenceProviders: data.objects })
      })
      .catch(err => {
        console.log(err)
      })
  }

  componentDidMount() {
    const landlordStorage = JSON.parse(localStorage.getItem('lldirect'))
    // Set initial state for showGuarantor and numberOfTenants if stored
    if (landlordStorage && landlordStorage.values.tenants) {
      const showGuarantor = {}
      landlordStorage.values.tenants.forEach((tenant, index) => {
        showGuarantor[index] = tenant.guarantor !== undefined
      })
      this.setState({
        showGuarantor,
        numberOfTenants: landlordStorage.values.tenants.length,
        values: landlordStorage.values,
      })
    }
    this.fetchReferenceProviders()
  }

  render() {
    const landlordStorage = JSON.parse(localStorage.getItem('lldirect'))
    const editingStorage = JSON.parse(localStorage.getItem('editing'))
    const { location } = this.props

    return (
      <Formik
        initialValues={
          landlordStorage ? landlordStorage.values : this.state.values
        }
        enableReinitialize={false}
        validationSchema={validationSchema}
        render={props => {
          return (
            <>
              <Header />
              <form
                onSubmit={e => this.handleSubmit(e, props)}
                className="formik-form"
              >
                <TransitionGroup>
                  <CSSTransition
                    key={location.pathname.split('/')[2]}
                    classNames="fade"
                    timeout={200}
                  >
                    <Switch location={location}>
                      <Route
                        path="/form/landlord"
                        render={() => (
                          <Landlord
                            {...props}
                            editing={
                              editingStorage && editingStorage.landlord
                                ? editingStorage.landlord
                                : this.state.editing.landlord
                            }
                          />
                        )}
                      />
                      <Route
                        path="/form/references"
                        render={() => (
                          <References
                            {...props}
                            referenceProviders={this.state.referenceProviders}
                            numberOfTenants={this.state.numberOfTenants}
                            setNumberOfTenants={this.setNumberOfTenants}
                            onStepNumberOfTenants={this.onStepNumberOfTenants}
                          />
                        )}
                      />
                      <Route
                        path="/form/tenants"
                        render={() => (
                          <TenantArray
                            {...props}
                            editing={
                              editingStorage && editingStorage.tenants
                                ? editingStorage.tenants
                                : this.state.editing.tenants
                            }
                            numberOfTenants={this.state.numberOfTenants}
                            incrementNumberOfTenants={
                              this.incrementNumberOfTenants
                            }
                            decrementNumberOfTenants={
                              this.decrementNumberOfTenants
                            }
                            showGuarantor={this.state.showGuarantor}
                            handleGuarantor={this.handleGuarantor}
                          />
                        )}
                      />
                      <Route
                        path="/form/property"
                        render={() => (
                          <Property
                            {...props}
                            editing={
                              editingStorage && editingStorage.property
                                ? editingStorage.property
                                : this.state.editing.property
                            }
                          />
                        )}
                      />
                      <Route
                        path="/form/review"
                        render={() => (
                          <Review
                            {...props}
                            handleEditing={this.handleEditing}
                            termsAndConditions={this.state.termsAndConditions}
                            handleTermsAndConditions={
                              this.handleTermsAndConditions
                            }
                            passedReferencesCheck={
                              this.state.passedReferencesCheck
                            }
                            handlePassedReferencesCheck={
                              this.handlePassedReferencesCheck
                            }
                          />
                        )}
                      />
                      <Redirect exact path="/form" to="/form/landlord" />
                      <Redirect exact path="/form/*" to="/form/landlord" />
                    </Switch>
                  </CSSTransition>
                </TransitionGroup>
                <Persist name="lldirect" data={props.values} />
              </form>
            </>
          )
        }}
      />
    )
  }
}

export default withRouter(WizardForm)
