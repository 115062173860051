import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { BUTTON_VARIANT_TYPES } from '../../utils/constants'

export default class Button extends React.Component {
  state = {
    ink: null,
    inkAnimate: false,
  }

  constructor() {
    super()
    this.buttonRef = React.createRef()
    this.inkTimer = null
  }

  handleClick = e => {
    const { onClick } = this.props

    // Get some sizes/positions for ink to be injected to button
    const bounds = this.buttonRef.current.getBoundingClientRect()
    const diameter = Math.max(bounds.width, bounds.height)
    const left = e.pageX - bounds.left - diameter * 0.5
    const top = e.pageY - bounds.top - diameter * 0.5

    // clear previous timers
    clearTimeout(this.inkTimer)

    // reset state
    this.setState({ ink: false }, () => {
      // add ink
      this.setState(
        {
          ink: true,
          inkAnimate: false,
          inkStyles: {
            top,
            left,
            width: diameter,
            height: diameter,
          },
        },
        () => {
          this.inkTimer = setTimeout(() => {
            // animate ink
            this.setState({ inkAnimate: true }, () => {
              this.inkTimer = setTimeout(() => {
                // remove ink after animation
                this.setState({ ink: false })
              }, 800)
            })
          })
        }
      )
    })

    if (onClick) onClick(e)
  }

  componentWillUnmount() {
    clearTimeout(this.inkTimer)
  }

  render() {
    const {
      children,
      className,
      disabled,
      fullWidth,
      href,
      icon,
      name,
      size,
      target,
      type,
      variant,
    } = this.props
    const { ink, inkAnimate, inkStyles } = this.state

    const RootNode = href ? 'a' : 'button'
    const classes = classNames(
      'Button',
      {
        [`Button--${variant}`]: !!variant,
        [`Button--${size}`]: !!size,
        [`Icons-${icon}`]: !!icon,
        'Button--span': fullWidth,
      },
      className
    )

    return (
      <RootNode
        className={classes}
        onClick={this.handleClick}
        ref={this.buttonRef}
        {...{ href, name, target, type, disabled }}
      >
        {children}
        {ink && (
          <div
            className={classNames('Button-ink', { animate: inkAnimate })}
            style={inkStyles}
          />
        )}
      </RootNode>
    )
  }
}

Button.defaultProps = {
  fullWidth: false,
  type: 'button',
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf([null, 'large', 'small']),
  type: PropTypes.string,
  variant: PropTypes.oneOf(BUTTON_VARIANT_TYPES),
}
