import React from 'react'
import Header from './Header'

const TellAgent = ({ history }) => {
  return (
    <>
      <Header history={history} />
      <div className="wrap-content u-push-large">
        <h2 className="u-push-3">
          Tell your agent you want to offer Zero Deposit
        </h2>
        <p className="u-push-3">
          {}
          It's that simple. If they're not a partner yet, they can sign up at{' '}
          <a href="https://www.zerodeposit.com/agents">
            zerodeposit.com/agents
          </a>
        </p>
      </div>
    </>
  )
}

export default TellAgent
